import { colors, type CompoundIconName } from '@orus.eu/pharaoh'
import type { AlertBannerRole } from './alert-banner-role'

export type AlertRoleConfiguration = {
  primaryColor: string
  secondaryColor: string
  backgroundColor: string
  borderColor: string
  boxShadow: string
  icon: CompoundIconName
}

export const alertRoleConfigurations: {
  [key in AlertBannerRole]: AlertRoleConfiguration
} = {
  informational: {
    primaryColor: colors.blue[900],
    secondaryColor: colors.blue[500],
    backgroundColor: colors.blue[100],
    borderColor: colors.blue[300],
    boxShadow: '0px 2px 16px rgba(34, 40, 47, 0.08)',
    icon: 'circle-info-regular',
  },
  informationalYellow: {
    primaryColor: colors.yellow[900],
    secondaryColor: colors.yellow[600],
    backgroundColor: colors.yellow[100],
    borderColor: colors.yellow[300],
    boxShadow: '0px 2px 16px rgba(34, 40, 47, 0.08)',
    icon: 'circle-arrow-right-regular',
  },
  warning: {
    primaryColor: colors.orange[900],
    secondaryColor: colors.orange[600],
    backgroundColor: colors.orange[100],
    borderColor: colors.orange[300],
    boxShadow: '0px 2px 16px rgba(34, 40, 47, 0.08)',
    icon: 'triangle-exclamation-regular',
  },
  danger: {
    primaryColor: colors.red[900],
    secondaryColor: colors.red[600],
    backgroundColor: colors.red[100],
    borderColor: colors.red[300],
    boxShadow: '0px 2px 16px rgba(34, 40, 47, 0.08)',
    icon: 'triangle-exclamation-regular',
  },
  success: {
    primaryColor: colors.green[900],
    secondaryColor: colors.green[700],
    backgroundColor: colors.green[100],
    borderColor: colors.green[300],
    boxShadow: '0px 2px 16px rgba(34, 40, 47, 0.08)',
    icon: 'circle-check-regular',
  },
}
